import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContextProvider } from './context/authContext';
import { LeavesContextProvider } from './context/leavesContext';

// Get the root element from your HTML
const rootElement = document.getElementById('root');

// Create a root using ReactDOM.createRoot
const root = ReactDOM.createRoot(rootElement);

// Render your app inside the root
root.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <LeavesContextProvider>
          <App />
        </LeavesContextProvider>
      </AuthContextProvider>
    </Router>
  </React.StrictMode>
);
