// UserSetting.js
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const UserSetting = () => {
    return (
        <div className="flex flex-col h-full">
          <div className='w-full bg-white p-3 rounded-lg mb-3'>Account</div>
            {/* Tabs Navigation */}
            <div className="rounded-t-lg px-5 pt-3 bg-white text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    <li className="me-2">
                        <NavLink
                            to="userInfo"
                            className={({ isActive }) =>
                                `inline-block p-4 border-b-2 rounded-t-lg ${
                                    isActive
                                        ? 'border-purple-600 text-purple-600 dark:border-purple-500 dark:text-purple-500'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`
                            }
                        >
                            Profile
                        </NavLink>
                    </li>
                    <li className="me-2">
                        <NavLink
                            to="billing"
                            className={({ isActive }) =>
                                `inline-block p-4 border-b-2 rounded-t-lg ${
                                    isActive
                                        ? 'border-purple-600 text-purple-600 dark:border-purple-500 dark:text-purple-500'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`
                            }
                        >
                            Billing
                        </NavLink>
                    </li>
                    <li className="me-2">
                        <NavLink
                            to="security"
                            className={({ isActive }) =>
                                `inline-block p-4 border-b-2 rounded-t-lg ${
                                    isActive
                                        ? 'border-purple-600 text-purple-600 dark:border-purple-500 dark:text-purple-500'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`
                            }
                        >
                            Security
                        </NavLink>
                    </li>
                    <li className="me-2">
                        <NavLink
                            to="contacts"
                            className={({ isActive }) =>
                                `inline-block p-4 border-b-2 rounded-t-lg ${
                                    isActive
                                        ? 'border-purple-600 text-purple-600 dark:border-purple-500 dark:text-purple-500'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`
                            }
                        >
                            Contacts
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* Tab Content */}
            <div className="flex-1 rounded-b-lg p-4 bg-white">
                <Outlet />
            </div>
        </div>
    );
};

export default UserSetting;
