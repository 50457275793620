import React from 'react'

import { useAuthContext } from "../../hooks/useAuthContext";



export const UserDashboard = () => {
  const { user } = useAuthContext();
  



  return (
    <div className='flex flex-wrap gap-4'>
      dashboard
    </div>
  )
}