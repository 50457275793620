import React from "react";
import { NavLink , Link } from "react-router-dom";
import { useEffect ,useState} from "react";
import AccountMenu from "../pages/cuurentuser/components/Menu";
import DotBadge from "../pages/cuurentuser/components/Notifications";
import LanguageSwitch from "../pages/cuurentuser/components/language";
export const Header = () => {
    const [darkMode,setDarkMode]=useState(JSON.parse(localStorage.getItem("darkMode")) || false);


    const activeClass="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700  md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent";
    const inActiveClass="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-purple-600 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent";
    useEffect(()=>{
        localStorage.setItem("darkMode", JSON.stringify(darkMode));

        if(darkMode){
            document.documentElement.classList.add("dark");
        }
        else
            document.documentElement.classList.remove("dark");
    },[darkMode]);
  
    return (
    <div >
      <nav className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-end mx-auto p-4 ">
            
          
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-4 rtl:space-x-reverse md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <LanguageSwitch/>
              </li>
              <li className="mt-3">
                <DotBadge/>
              </li>
              
              <li>
                <button onClick={()=>setDarkMode(!darkMode)} data-tooltip-target=" navbar-search-example-toggle-dark-mode-tooltip" type="button" data-toggle-dark="light" className="max-md:mt-2 mt-3  flex items-center w-9 h-9 justify-center text-xs font-medium text-gray-700 bg-g border border-gray-200 rounded-lg toggle-dark-state-example hover:bg-gray-100 hover:text-purple-600 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 ">
                        {!darkMode ?
                        ( <svg data-toggle-icon="moon" className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M17.8 13.75a1 1 0 0 0-.859-.5A7.488 7.488 0 0 1 10.52 2a1 1 0 0 0 0-.969A1.035 1.035 0 0 0 9.687.5h-.113a9.5 9.5 0 1 0 8.222 14.247 1 1 0 0 0 .004-.997Z"></path>
                        </svg>
                        ) 
                        :
                        (<svg data-toggle-icon="sun" className="w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 15a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0-11a1 1 0 0 0 1-1V1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm0 12a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1ZM4.343 5.757a1 1 0 0 0 1.414-1.414L4.343 2.929a1 1 0 0 0-1.414 1.414l1.414 1.414Zm11.314 8.486a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM4 10a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm15-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2ZM4.343 14.243l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414a1 1 0 0 0-1.414-1.414ZM14.95 6.05a1 1 0 0 0 .707-.293l1.414-1.414a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 .707 1.707Z"></path>
                    </svg>)}
                </button>
                </li>
                <li className="mt-2">
                  <AccountMenu />
                </li>

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
