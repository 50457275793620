import React, { useState, useEffect, useRef } from "react";
import { CiStreamOn } from "react-icons/ci";

function CarCard({ carInfo }) {
  // State to track overlay and custom dropdown visibility
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [customDropdownOpen, setCustomDropdownOpen] = useState(false);

  // Ref to the card element
  const cardRef = useRef(null);

  // Function to toggle overlay visibility
  const toggleOverlay = () => {
    setOverlayVisible((prev) => !prev);
    setCustomDropdownOpen(false); // Hide dropdown when overlay is toggled
  };

  // Function to toggle custom dropdown visibility
  const toggleCustomDropdown = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    setCustomDropdownOpen((prev) => !prev);
  };

  // Event handler for detecting outside clicks
  const handleOutsideClick = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setOverlayVisible(false);
      setCustomDropdownOpen(false);
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Handlers for dropdown actions
  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the card
    console.log("Edit clicked");
    setCustomDropdownOpen(false); // Close dropdown after action
  };

  const handleExport = (e) => {
    e.stopPropagation();
    console.log("Export Data clicked");
    setCustomDropdownOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    console.log("Delete clicked");
    setCustomDropdownOpen(false);
  };

  return (
    <div
      ref={cardRef}
      onClick={toggleOverlay}
      className="relative w-full max-w-sm p-5 sm:p-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 overflow-hidden cursor-pointer mb-3"
    >
      {/* Overlay and Blur on Click */}
      <div
        className={`absolute inset-0 bg-black/50 ${
          overlayVisible ? "opacity-5" : "opacity-0"
        } transition-opacity ease-in-out duration-300 z-10`}
      ></div>

      <div className="flex justify-end px-4 pt-4 relative z-20">
        {/* Custom Dropdown Button */}
        <button
          id="customDropdownButton"
          onClick={toggleCustomDropdown}
          className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
          type="button"
        >
          <span className="sr-only">Open dropdown</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 3"
          >
            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
          </svg>
        </button>

        {/* Custom Dropdown Menu */}
        {customDropdownOpen && (
          <div
            id="customDropdown"
            className="z-50 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 absolute right-0 mt-2"
            onClick={(e) => e.stopPropagation()} // Stop clicks from bubbling to parent
          >
            <ul className="py-2" aria-labelledby="customDropdownButton">
              <li>
                <a
                  href="#"
                  onClick={handleEdit}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Edit
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleExport}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Export Data
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={handleDelete}
                  className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>

      <div
        className={`flex flex-col items-center pb-1 transition ease-in-out duration-300 ${
          overlayVisible ? "blur-sm" : ""
        } relative z-20`}
      >
        <img
          className="w-24 h-24 mb-3 rounded-full shadow-lg"
          src="/docs/images/people/profile-picture-3.jpg"
          alt="Bonnie image"
        />
        <div className="flex flex-row justify-between gap-20">
          <div className="flex flex-col">
            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
              {carInfo.name}
            </h5>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              {carInfo.driver}
            </span>
          </div>
          <div className="w-max py-5">
            <div
              className={`relative grid items-center font-bold uppercase whitespace-nowrap select-none ${
                carInfo.status === "On Road"
                  ? "bg-green-500/20 text-green-600"
                  : carInfo.status === "Parked"
                  ? "bg-red-500/20 text-red-700"
                  : "bg-gray-200 text-gray-500"
              } py-1 px-2 text-xs rounded-md`}
            >
              <span>{carInfo.status}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Overlay Buttons in Center */}
      {overlayVisible && (
        <div className="absolute inset-0 flex items-center justify-center gap-4 opacity-100 transition ease-in-out duration-300 z-30">
          <a
            href="#"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
          >
            Details
          </a>
          <a
            href="#"
            className="flex flex-row gap-2 py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-purple-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            <CiStreamOn size={20} />
            Go Live
          </a>
        </div>
      )}
    </div>
  );
}

export default CarCard;
