import React, { useState, useRef, useEffect } from 'react';
import { IoMdNotificationsOutline } from 'react-icons/io';

export default function DotBadge() {
  // State for managing dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Sample list of notifications
  const notifications = [
    { id: 1, message: 'You have a new message from John Doe' },
    { id: 2, message: 'Your order has been shipped!' },
    { id: 3, message: 'Reminder: Meeting at 3 PM' },
    { id: 4, message: 'New comment on your post' },
    { id: 5, message: 'Your password was changed successfully' },
  ];

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  // Close the dropdown if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      {/* Notification Button */}
      <button
        onClick={toggleDropdown} // Toggle dropdown on click
        className="relative inline-flex items-center pl-2 w-9 h-9 text-sm font-medium text-center text-gray-700 bg-white border border-gray-200 rounded-lg toggle-dark-state-example hover:bg-gray-100 hover:text-purple-600 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        <IoMdNotificationsOutline size={20} />
        <span className="sr-only">Notifications</span>
        {/* Badge for notification count */}
        <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -right-1 dark:border-gray-900">
          {notifications.length}
        </div>
      </button>

      {/* Dropdown Menu with Animation */}
      <div
        className={`absolute right-0 mt-2 w-80 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-600 z-50 transition-transform duration-300 ease-in-out ${
          isDropdownOpen
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 -translate-y-4 pointer-events-none'
        }`}
      >
        <ul className="py-1 max-h-60 overflow-y-auto">
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className="px-4 py-2 border-b border-gray-100 dark:border-gray-700"
            >
              <span className="block text-sm text-gray-800 dark:text-gray-300">
                {notification.message}
              </span>
            </li>
          ))}
          {notifications.length === 0 && (
            <li className="px-4 py-2 text-sm text-gray-500 dark:text-gray-400">
              No new notifications
            </li>
          )}
        </ul>
        <div className="rounded-lg px-4 py-2 text-sm text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-300 border-t border-gray-100 dark:border-gray-600">
          <button
            className="text-purple-500 hover:underline dark:text-purple-400"
            onClick={() => alert('View all notifications')}
          >
            View all
          </button>
        </div>
      </div>
    </div>
  );
}
