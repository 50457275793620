import React, { useState, useRef, useEffect } from 'react';
import { IoLanguage } from 'react-icons/io5';

const LanguageSwitch = () => {
  // State for managing dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  // Close the dropdown if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      {/* Language Button */}
      <button
        onClick={toggleDropdown} // Toggle dropdown on click
        className="relative inline-flex items-center justify-center mt-3 w-9 h-9 text-xs font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-purple-600 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        <IoLanguage size={20} />
        <span className="sr-only">Language Switch</span>
      </button>

      {/* Dropdown Menu */}
      <div
        className={`absolute right-0 mt-2 w-fit bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-600 z-50 transition-transform duration-300 ease-out ${
          isDropdownOpen
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 -translate-y-4 pointer-events-none'
        }`}
      >
        <ul className="py-1">
          <li>
            <button
              className="flex flex-row gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
              onClick={() => alert('English selected')}
            >
              English
              <span className="pt-0.5 text-xs text-gray-500 dark:text-gray-400">(US)</span>
            </button>
          </li>
          <li>
            <button
              className="flex flex-row gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
              onClick={() => alert('Arabic selected')}
            >
              Arabic
              <span className="pt-0.5 text-xs text-gray-500 dark:text-gray-400">(AR)</span>
            </button>
          </li>
          <li>
            <button
              className="flex flex-row gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
              onClick={() => alert('French selected')}
            >
              French
              <span className="pt-0.5 text-xs text-gray-500 dark:text-gray-400">(FR)</span>
            </button>
          </li>
          {/* Add more languages as needed */}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSwitch;
