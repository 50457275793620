import React from 'react';
import SideBar from './components/SideBar';
import { Outlet } from "react-router-dom";
import { Header } from '../../layout';
import TemporaryDrawer from './components/Drawer';

const UserHome = () => {
    return (
        <div className='flex flex-row'>
            {/* Sidebar for PC */}
            <div className="hidden md:flex">
                <SideBar />
            </div>

            {/* Temporary Drawer for Mobile */}
            <div className="md:hidden">
                <TemporaryDrawer />
            </div>

            <div className='flex flex-col w-full'>
                <Header />
                <div className="flex-1 p-6 bg-gray-100 rounded-l-xl dark:bg-gray-900">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default UserHome;
