import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
//import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import ReactDOM from "react-dom";
//import geoJson from './../../assets/geoSousse.json'
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
let geoJson = {
  "features": [
    {
      "type": "Feature",
      "properties": {
        "title": "Lincoln Park",
        "description": "A northside park that is home to the Lincoln Park Zoo",
        "status": "marker-parking"
      },
      "geometry": {
        "coordinates": [10.628875, 35.821904 ],
        "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Burnham Park",
        "description": "A lakefront park on Chicago's south side",
        "status": "marker-disconnected"
      },
      "geometry": {
          "coordinates": [10.640621, 35.827943],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Millennium Park",
        "description": "A downtown park known for its art installations and unique architecture",
        "status": "marker-driving"
      },
      "geometry": {
          "coordinates": [10.639410, 35.834445],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Grant Park",
        "description": "A downtown park that is the site of many of Chicago's favorite festivals and events",
        "status": "marker-parking"
      },
      "geometry": {
          "coordinates": [10.626266, 35.8401098],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Humboldt Park",
        "description": "A large park on Chicago's northwest side",
        "status": "marker-driving"
      },
      "geometry": {
          "coordinates": [10.613367, 35.82089],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Douglas Park",
        "description": "A large park near in Chicago's North Lawndale neighborhood",
        "status": "marker-disconnected"
      },
      "geometry": {
          "coordinates": [10.634174, 35.824558],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Calumet Park",
        "description": "A park on the Illinois-Indiana border featuring a historic fieldhouse",
        "status": "marker-driving"
      },
      "geometry": {
          "coordinates": [10.622563, 35.817251],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Jackson Park",
        "description": "A lakeside park that was the site of the 1893 World's Fair",
        "status": "marker-parking"
      },
      "geometry": {
          "coordinates": [10.625756, 35.811735],
          "type": "Point"
      }
    },
    {
      "type": "Feature",
      "properties": {
        "title": "Columbus Park",
        "description": "A large park in Chicago's Austin neighborhood",
        "status": "marker-driving"
      },
      "geometry": {
          "coordinates": [10.638698, 35.810057],
          "type": "Point"
      }
    }
  ],
  "type": "FeatureCollection"
}

let speed=0.01;
let manual=true;
let direction=0;
  function setPosition() {
    geoJson.features[1].geometry.coordinates[0] += speed * Math.cos(direction) / 100;
    geoJson.features[1].geometry.coordinates[1] += speed * Math.cos(direction) / 100;
    geoJson.features[0].geometry.coordinates[0] += speed * Math.cos(direction) / 100;
    geoJson.features[0].geometry.coordinates[1] += speed * Math.cos(direction) / 100;
    geoJson.features[3].geometry.coordinates[0] += speed * Math.cos(direction) / 100;
    geoJson.features[3].geometry.coordinates[1] += speed * Math.cos(direction) / 100;

    if (!manual && Math.random() > 0.95) {
        direction += (Math.random() - 0.5) / 2;
    }

}
window.setInterval(setPosition, 10);


const Map = () => {
  const mapContainerRef = useRef();
  const mapRef = useRef();

  const bounds = [
    [7.441993, 30.211155, ], // Southwest coordinates
    [11.486968, 37.413450 ] // Northeast coordinates
  ];
  


  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoidGF5ZWItbW9rbmkiLCJhIjoiY2x6MTd3Y2M1MGJzdDJscGZrOTlvMDNncSJ9.AcP3GcTkAiVQKB1yuESkxg';

    const Marker = ({ onClick, children, feature }) => {
      const _onClick = () => {
        onClick(feature.properties.description);
      };

      return (
        <button onClick={_onClick} className={feature.properties.status}>
          {children}
        </button>
      );
    };

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      style: 'mapbox://styles/tayeb-mokni/clz2ri40800me01pc4qg4hz3u',
      center: [10.634174, 35.824558],
      zoom: 13,
      maxBounds: bounds
    });
let ttt = {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              [10.634361, 35.824581],
              [10.633394, 35.824534],
              [10.632394, 35.824534],
              [10.631394, 35.824534],
              [10.630394, 35.824534],
              [10.629394, 35.824534],
              [10.628394, 35.824534],
              [10.627394, 35.824534],
              [10.626394, 35.824534],
              [10.625394, 35.824534],
              [10.624394, 35.824534],
              [10.623394, 35.824534],
              [10.622394, 35.824534],
              [10.621394, 35.824534]
            ]
          }
        }
      }
   /* mapRef.current.on('load', () => {
      mapRef.current.addSource("source_1", ttt);

      mapRef.current.addLayer({
        id: 'route',
        type: 'LineString',
        source: "TTT",
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#90EE90',
          'line-width': 8
        }
      });
    });*/
    // Create default markers
    //geoJson.features.map((feature) =>
    //   new mapboxgl.Marker().setLngLat(feature.geometry.coordinates).addTo(mapRef.current)
    //  );


    // Render custom marker components
    geoJson.features.forEach((feature) => {
      // Create a React ref
      const ref = React.createRef();
      // Create a new DOM node and save it to the React ref
      ref.current = document.createElement("div");
      // Render a Marker Component on our new DOM node
      ReactDOM.render(
        <Marker onClick={markerClicked} feature={feature} />,
        ref.current
      );

      // Create a Mapbox Marker at our new DOM node
      new mapboxgl.Marker(ref.current)
        .setLngLat(feature.geometry.coordinates)
        .addTo(mapRef.current);
    });

    // Add navigation control (the +/- zoom buttons)
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-right");


    /* Given a query in the form "lng, lat" or "lat, lng"
     * returns the matching geographic coordinate(s)
     * as search results in carmen geojson format,
     * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
    /*
   const coordinatesGeocoder = (query) => {
     // Match anything which looks like
     // decimal degrees coordinate pair.
     const matches = query.match(
       /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
     );
     if (!matches) {
       return null;
     }

     function coordinateFeature(lng, lat) {
       return {
         center: [lng, lat],
         geometry: {
           type: 'Point',
           coordinates: [lng, lat]
         },
         place_name: 'Lat: ' + lat + ' Lng: ' + lng,
         place_type: ['coordinate'],
         properties: {},
         type: 'Feature'
       };
     }

     const coord1 = Number(matches[1]);
     const coord2 = Number(matches[2]);
     const geocodes = [];

     if (coord1 < -90 || coord1 > 90) {
       // must be lng, lat
       geocodes.push(coordinateFeature(coord1, coord2));
     }

     if (coord2 < -90 || coord2 > 90) {
       // must be lat, lng
       geocodes.push(coordinateFeature(coord2, coord1));
     }

     if (geocodes.length === 0) {
       // else could be either lng, lat or lat, lng
       geocodes.push(coordinateFeature(coord1, coord2));
       geocodes.push(coordinateFeature(coord2, coord1));
     }

     return geocodes;
   };

   // Add the control to the map (You will need to import MapboxGeocoder)
   mapRef.current.addControl(
     new MapboxGeocoder({
       accessToken: mapboxgl.accessToken,
       localGeocoder: coordinatesGeocoder,
       zoom: 4,
       placeholder: 'Where to go...',
       mapboxgl: mapboxgl,
       reverseGeocode: true
     })
   );*/
  }, [geoJson.features]);

  const markerClicked = (title) => {
    window.alert(title);
  };


  return <div ref={mapContainerRef} style={{ height: '100%' }} />;
};

export default Map;
