import React, { useState } from 'react'
import { Link, NavLink, Outlet } from "react-router-dom";
import {AiOutlineCalendar, AiOutlineClose, AiOutlineDashboard, AiOutlineMenu, AiOutlineProfile} from 'react-icons/ai'
import { IoCarOutline } from "react-icons/io5";
import { BsGeoAlt } from "react-icons/bs";

const SideBar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const navElements = [
        {
            title: 'Dashboard',
            href: '/user/dashboard',
            icon: <AiOutlineDashboard size={40} className={` text-gray-700 ${isCollapsed ? 'hover:bg-gray-200 hover:text-purple-600' : ''} rounded-lg p-2`}/>
        },
        {
            title: 'Vechiles',
            href: '/user/cars',
            icon: <IoCarOutline size={40} className={` text-gray-700 ${isCollapsed ? 'hover:bg-gray-200 hover:text-purple-600' : ''} rounded-lg p-2`}/>
        },
        {
            title: 'Map',
            href: '/user/map',
            icon: <BsGeoAlt size={40} className={` text-gray-700 ${isCollapsed ? 'hover:bg-gray-200 hover:text-purple-600' : ''} rounded-lg p-2`}/>
        },
        {
            title: 'Profile',
            href: '/user/dashboard',
            icon: <AiOutlineProfile size={40} className={` text-gray-700 ${isCollapsed ? 'hover:bg-gray-200 hover:text-purple-600' : ''} rounded-lg p-2`}/>
        },
        {
            title: 'Calendar',
            href: '/user/dashboard',
            icon: <AiOutlineCalendar size={40} className={` text-gray-700 ${isCollapsed ? 'hover:bg-gray-200 hover:text-purple-600' : ''} rounded-lg p-2`}/>
        },
    ]
  return (
    <div className={`h-screen bg-white text-gray-700 flex flex-col ${isCollapsed ? 'w-20' : 'w-64'} transition-all duration-300`}>
        <div className='flex items-center justify-between h-20 bg-white  p-4'>
            {
                !isCollapsed && (
                    <h1 className='text-3xl font-bold text-gray-900'>
                            <Link to="#" className="flex items-center space-x-3 rtl:space-x-reverse">
            {/* <img src={Logo} className="h-8" alt="TimeAwayTrek" /> */}
                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">DoTrack</span>
            </Link>
                    </h1>
                )
            }
            <button onClick={() => setIsCollapsed(!isCollapsed)}
                className='text-gray-700 focus:outline-none'>
                        {isCollapsed ? <AiOutlineMenu size={40} className='rounded-lg p-2 hover:bg-purple-400'/> : <AiOutlineClose size={40} className='rounded-lg p-2 hover:bg-purple-400'/>}
            </button>
        </div>
        <div className='flex-1 flex flex-col justify-between overflow-hidden'>
            <nav className='mt-1'>
                {navElements.map((navElement) => (
                    <NavLink to={navElement.href} key={navElement.title}>
                        <div className={`flex items-center text-sm py-2 px-2 mx-2 rounded-lg transition duration-300  hover:text-purple-600 ${isCollapsed ? 'justify-center' : 'hover:bg-gray-200 '}`}> 
                            {navElement.icon}
                            <span className={`ml-2 transition-opacity duration-300 delay-200 ${isCollapsed ? 'opacity-0': 'opacity-100'}`}>
                                {!isCollapsed && navElement.title}
                            </span>
                        </div>
                    </NavLink>
                ))}
            </nav>
            {/* <div className='mb-10 transition-opacity duration-300 delay-300'>
                <button className='flex item-center py-2.5 px-4 w-full texl-left rounder transition duration-300 hover:bg-red-400 hover:text-white'>
                    <AiOutlineLogout className='w-6 h-6 mr-2 text-gray-700'/>
                    <span className={`transition duration-300 delay-300 ${isCollapsed ? 'opacity-0': 'opacity-100'}`}>
                        {
                            !isCollapsed && "logout"
                        }
                    </span>
                </button>
            </div> */}
        </div>
    </div>
    
  )
}

export default SideBar