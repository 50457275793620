import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from '../pages/login';
import { PageNotFound } from '../pages/PageNotFound';
import { SignUp } from '../pages/signup';
import AdminHome from '../pages/admin/adminHome';
import { AdminProfile } from '../pages/admin/adminprofile';
import Dashboard from '../pages/admin/processus';
import RequestList from '../pages/admin/requestlist';
import { Users } from '../pages/admin/employes';
import UserHome from '../pages/cuurentuser/userHome';
import DemandeUser from '../pages/cuurentuser/demandeUser';
import { About } from '../pages/About';
import { EmployeeHome } from '../pages/cuurentuser/notificationUser';
import { NotificationAdmin } from '../pages/admin/notificationsAdmin';
import { useAuthContext } from '../hooks/useAuthContext';
import { UserDashboard } from '../pages/cuurentuser/DashboardUser';
import Map from '../pages/cuurentuser/map';
import Cars from '../pages/cuurentuser/Cars';
import UserSetting from '../pages/cuurentuser/UserSetting';
import UserInfo from '../pages/cuurentuser/profileComponents/UserInfo';
import { BillingPresentation } from '../pages/cuurentuser/profileComponents/BillingCard';
import Security from '../pages/cuurentuser/profileComponents/Security';

export const AllRoutes = () => {
  const { user } = useAuthContext();

  return (
    <Routes>
      {/* Default Route to redirect to Login */}
      <Route 
        path="/" 
        element={<Navigate to="/login" replace />} 
      />

      <Route 
        path="/login" 
        element={
          !user ? (
            <LoginPage />
          ) : user.isAdmin === true ? (
            <Navigate to="/admin" />
          ) : (
            <Navigate to="/user" />
          )
        } 
      />

      <Route 
        path="/signup" 
        element={
          !user ? (
            <SignUp />
          ) : user.isAdmin === true ? (
            <Navigate to="/admin" />
          ) : (
            <Navigate to="/user" />
          )
        } 
      />

      <Route path="/about" element={<About />} />

      <Route 
        path="/admin" 
        element={user ? <AdminHome /> : <Navigate to="/login" />}
      >
        <Route path="profile" element={<AdminProfile />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route index element={<Dashboard />} />
        <Route path="demandes" element={<RequestList />} />
        <Route path="notificationAdmin" element={<NotificationAdmin />} />
        <Route path="employees" element={<Users />} />
      </Route>

      <Route 
        path="/user" 
        element={user ? <UserHome /> : <Navigate to="/login" />}
      >
        <Route path="dashboard" element={<UserDashboard />} />
        <Route path="map" element={<Map />} />
        <Route path="cars" element={<Cars />} />
        <Route index element={<UserDashboard />} />
        <Route path="demandeUser" element={<DemandeUser />} />
        
        <Route path="userSetting" element={<UserSetting />}>
            <Route path="userInfo" element={<UserInfo />} />
            <Route path="billing" element={<BillingPresentation />} />
            <Route path="security" element={<Security />} />
        </Route>

        <Route path="notificationUser" element={<EmployeeHome />} />
        
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
