import React from 'react'
import CarCard from './components/card'

const Cars = () => {
  const cars = [
    { id: 1, name: 'Toyota Camry', driver: 'Alice Johnson', status: 'On Road' },
    { id: 2, name: 'Honda Accord', driver: 'Bob Smith', status: 'Parked' },
    { id: 3, name: 'Ford Explorer', driver: 'Charlie Brown', status: 'On Road' },
    { id: 4, name: 'Chevrolet Malibu', driver: 'Diana Ross', status: 'Parked' },
    { id: 5, name: 'Nissan Altima', driver: 'Evan Taylor', status: 'On Road' },
  ];
  return (
    <div className='grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-1  '>
        {cars.map((car) => (
          <CarCard key={car.id} carInfo={car}/>
        ))}
    </div>
  )
}

export default Cars
