import React from 'react'
import { AiOutlineArrowRight } from "react-icons/ai";

function BillingCard({ heading, description, lineColor,linkColor, className,click }) {
    return (
      <div className='flex flex-row '>
                <div className={`${lineColor} h-full w-2 rounded-l-lg`}></div>
      <div className={`text-sm flex gap-4 rounded-r-xl shadow-sm p-6 w-full ${className}`}>
        <div className="space-y-2">
        <p className="leading-8 text-gray-500 font-normal">{heading}</p>
          <h3 className="text-[22px] font-semibold">{description}</h3>
          <div className='flex flex-row'>
          <a href="#" className={`${linkColor}`}>{click}
          </a>
          <AiOutlineArrowRight className={`${linkColor} mt-1 mx-1`} />
          </div>
        </div>
      </div>
      </div>
    );
  }
export function BillingPresentation() {
  const bills = [
    {
      orderNumber: 1,
      date: "2024-07-20",
      price: "$299.99",
      status: "Paid",
    },
    {
      orderNumber: 2,
      date: "2024-07-21",
      price: "$399.99",
      status: "Awaiting",
    },
    {
      orderNumber: 3,
      date: "2024-07-22",
      price: "$99.99",
      status: "Paid",
    },
    {
      orderNumber: 4,
      date: "2024-07-23",
      price: "$499.99",
      status: "Awaiting",
    },
    {
      orderNumber: 5,
      date: "2024-07-24",
      price: "$59.99",
      status: "Paid",
    },
  ];
  
    return (
      <div className='flex flex-col p-3 sm:p-5 gap-8'>
      <div className=" grid gap-8 grid-cols-1 md:grid-cols-3 xl:grid-cols-3 bg-white ">
        <BillingCard
          className="bg-red-100"
          heading="100"
          click="Pay Now"
          description="Bill Due"
          lineColor="bg-red-600"
          linkColor="text-red-600"
        />
        <BillingCard
          className="bg-purple-100"
          heading="5165"
          click="Full report"
          description="Total Credits"
          lineColor="bg-purple-600"
          linkColor="text-purple-600"          />
        <BillingCard
          className="bg-yellow-50"
          heading="basic"
          click="Upgrade"
          description="Plan"
          lineColor="bg-yellow-400"
          linkColor="text-yellow-400"         />
      </div>
      <div>
      
          

      
    <div className="relative overflow-x-auto shadow-sm rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border border-b">
        <thead className="font-medium text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th colSpan="5" className="font-medium px-6 py-3">
              Billing History
              <hr  className='bg-black w-full mt-4'/>
            </th>
          </tr>
          <tr>
            <th scope="col" className="px-6 py-3 font-medium ">
            Order No.
            </th>
            <th scope="col" className="px-6 py-3 font-medium ">
            Date
            </th>
            <th scope="col" className="px-6 py-3 font-medium ">
            Price
            </th>
            <th scope="col" className="px-6 py-3 font-medium ">
            Status
            </th>
          </tr>
        </thead>
        <tbody>
          {bills.map((bill) => (
            <tr
              key={bill.orderNumber}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
                scope="row"
                className="px-6 py-2 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white"
              >
                {bill.orderNumber}
              </th>
              <td className="px-6 py-2">{bill.date}</td>
              <td className="px-6 py-2">{bill.price}</td>
              <td className="px-6 py-2">
              <div className="w-max py-5">
            <div
              className={`relative grid items-center whitespace-nowrap select-none ${
                bill.status === "Paid"
                  ? "bg-green-500/20 text-green-600 hover:text-gray-200  hover:bg-green-600"
                  : bill.status === "Awaiting"
                  ? "bg-yellow-100 text-yellow-600 hover:text-gray-50 hover:bg-yellow-500"
                  : "bg-gray-200 text-gray-500"
              } py-1 px-2 text-xs rounded-md`}
            >
              <span>{bill.status}</span>
            </div>
          </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

      </div>
      </div>
    );
  }